import type { TimeOfDayName } from '@redux/features/trainFilters/constants/trainFilters.constants'
import { TrainFilters } from '@redux/features/trainFilters/constants/trainFilters.constants'
import type {
  FiltersType,
  ITrainFiltersState,
  RangeValueType,
  TravelClassFilterType,
} from '@redux/features/trainFilters/types/trainFiltersState'
import { filterTrains } from '@redux/features/trainFilters/utils/filterTrains'
import { updateStationFilterState } from '@redux/features/trainFilters/utils/station'
import { getTimeOfDayFilters } from '@redux/features/trainFilters/utils/timeOfDay'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { IRoute } from '@Types/routes/route'

// need to be refactored: remove filteredTrains, initTrains, add hiddenTrainsKeys
const initialState: ITrainFiltersState = {
  filteredTrains: {},
  filters: {
    [TrainFilters.ARRIVAL_STATION]: [],
    [TrainFilters.ARRIVAL_TIME]: [],
    [TrainFilters.DEPARTURE_STATION]: [],
    [TrainFilters.DEPARTURE_TIME]: [],
    [TrainFilters.PRICE]: [],
    [TrainFilters.TRAVEL_CLASS]: [],
    [TrainFilters.TRAVEL_TIME]: [],
  },
  initFilters: {
    [TrainFilters.ARRIVAL_STATION]: [],
    [TrainFilters.ARRIVAL_TIME]: [],
    [TrainFilters.DEPARTURE_STATION]: [],
    [TrainFilters.DEPARTURE_TIME]: [],
    [TrainFilters.PRICE]: [],
    [TrainFilters.TRAVEL_CLASS]: [],
    [TrainFilters.TRAVEL_TIME]: [],
  },
  initTrains: {},
  isFilterByRecommendedStations: true,
  isShowNotificationRecommendedStations: false,
  isShowRecommendedStationBadge: false,
  recommended_arrival_stations: [],
  recommended_departure_stations: [],
}

export const trainFiltersSlice = createSlice({
  initialState,
  name: 'trainFilters',
  reducers: {
    resetFilters: state => {
      state.filteredTrains = state.initTrains
      state.filters = state.initFilters
      state.isShowNotificationRecommendedStations = false
      state.isFilterByRecommendedStations = true
    },

    setFilterRange: (
      state,
      action: PayloadAction<{ name: TrainFilters.PRICE | TrainFilters.TRAVEL_TIME; value: RangeValueType }>
    ) => {
      state.filters[action.payload.name] = action.payload.value
      state.filteredTrains = filterTrains(state.initTrains, state.filters)
      state.isShowNotificationRecommendedStations = false
    },

    setFilterStation: (
      state,
      action: PayloadAction<{
        name: TrainFilters.ARRIVAL_STATION | TrainFilters.DEPARTURE_STATION
        stationName: string
      }>
    ) => {
      state.filters[action.payload.name] = state.filters[action.payload.name].map(station => {
        return {
          ...station,
          value: station.name === action.payload.stationName ? (station.value ? 0 : 1) : station.value,
        }
      })
      state.filteredTrains = filterTrains(state.initTrains, state.filters)
      state.isShowNotificationRecommendedStations = false
    },
    setFilterTimeOfDay: (
      state,
      action: PayloadAction<{
        checkboxName: TimeOfDayName
        name: TrainFilters.ARRIVAL_TIME | TrainFilters.DEPARTURE_TIME
      }>
    ) => {
      state.filters[action.payload.name] = state.filters[action.payload.name].map(item => {
        return {
          ...item,
          value: item.name === action.payload.checkboxName ? (item.value ? 0 : 1) : item.value,
        }
      })
      state.filteredTrains = filterTrains(state.initTrains, state.filters)
      state.isShowNotificationRecommendedStations = false
    },

    setFilterTravelClass: (
      state,
      action: PayloadAction<{
        travelClassName: string
      }>
    ) => {
      state.filters[TrainFilters.TRAVEL_CLASS] = state.filters[TrainFilters.TRAVEL_CLASS].map(item => {
        return {
          ...item,
          value: item.name === action.payload.travelClassName ? (item.value ? 0 : 1) : item.value,
        }
      })
      state.filteredTrains = filterTrains(state.initTrains, state.filters)
      state.isShowNotificationRecommendedStations = false
    },

    setInitFiltersRangeValue: (
      state,
      action: PayloadAction<{ name: TrainFilters.PRICE | TrainFilters.TRAVEL_TIME; value: RangeValueType }>
    ) => {
      state.filters[action.payload.name] = action.payload.value
      state.initFilters[action.payload.name] = action.payload.value
    },

    setInitFiltersTimeOfDay: (
      state,
      action: PayloadAction<{
        initTrains: IRoute['trains']
        name: TrainFilters.ARRIVAL_TIME | TrainFilters.DEPARTURE_TIME
      }>
    ) => {
      const timeOfDayFilters = getTimeOfDayFilters({
        filteredTrains: action.payload.initTrains,
        initTrains: action.payload.initTrains,
        name: action.payload.name,
      })
      state.initFilters[action.payload.name] = timeOfDayFilters
      state.filters[action.payload.name] = timeOfDayFilters
    },

    setInitTrains: (state, action: PayloadAction<IRoute['trains']>) => {
      state.initTrains = action.payload
      state.filteredTrains = action.payload
      state.isShowNotificationRecommendedStations = false
    },

    setInitTrainsFilters: (
      state,
      {
        payload,
      }: PayloadAction<
        Pick<
          ITrainFiltersState,
          | 'filteredTrains'
          | 'initTrains'
          | 'isShowNotificationRecommendedStations'
          | 'isShowRecommendedStationBadge'
          | 'recommended_arrival_stations'
          | 'recommended_departure_stations'
        > & {
          arrivalStationFilter: FiltersType[TrainFilters.ARRIVAL_STATION]
          departureStationFilter: FiltersType[TrainFilters.DEPARTURE_STATION]
          initArrivalStationFilter: FiltersType[TrainFilters.ARRIVAL_STATION]
          initDepartureStationFilter: FiltersType[TrainFilters.DEPARTURE_STATION]
        }
      >
    ) => {
      state.initTrains = payload.initTrains
      state.filteredTrains = state.isFilterByRecommendedStations ? payload.filteredTrains : payload.initTrains
      state.recommended_arrival_stations = payload.recommended_arrival_stations
      state.recommended_departure_stations = payload.recommended_departure_stations
      state.isShowNotificationRecommendedStations =
        payload.isShowNotificationRecommendedStations && state.isFilterByRecommendedStations
      state.isShowRecommendedStationBadge = payload.isShowRecommendedStationBadge
      state.initFilters[TrainFilters.ARRIVAL_STATION] = payload.initArrivalStationFilter
      state.initFilters[TrainFilters.DEPARTURE_STATION] = payload.initDepartureStationFilter

      state.filters[TrainFilters.ARRIVAL_STATION] = state.isFilterByRecommendedStations
        ? payload.arrivalStationFilter
        : payload.initArrivalStationFilter
      state.filters[TrainFilters.DEPARTURE_STATION] = state.isFilterByRecommendedStations
        ? payload.departureStationFilter
        : payload.initDepartureStationFilter
    },

    setIsFilterByRecommendedStations: (state, action: PayloadAction<boolean>) => {
      state.isFilterByRecommendedStations = action.payload
    },

    setIsShowNotification: (state, action: PayloadAction<boolean>) => {
      state.isShowNotificationRecommendedStations = action.payload
    },

    setTravelClassFilters: (
      state,
      { payload }: PayloadAction<{ filters: TravelClassFilterType[]; initFilters?: TravelClassFilterType[] }>
    ) => {
      if (payload.initFilters) state.initFilters[TrainFilters.TRAVEL_CLASS] = payload.initFilters
      state.filters[TrainFilters.TRAVEL_CLASS] = payload.filters
    },

    setUpdateFiltersStation: (
      state,
      action: PayloadAction<{
        filteredTrains: IRoute['trains']
        initTrains: IRoute['trains']
        name: TrainFilters.ARRIVAL_STATION | TrainFilters.DEPARTURE_STATION
      }>
    ) => {
      const prevFilters = state.filters[action.payload.name]
      state.filters[action.payload.name] = updateStationFilterState(
        action.payload.initTrains,
        action.payload.name,
        action.payload.filteredTrains,
        prevFilters
      )
    },

    setUpdateFiltersTimeOfDay: (
      state,
      action: PayloadAction<{
        filteredTrains: IRoute['trains']
        initTrains: IRoute['trains']
        name: TrainFilters.ARRIVAL_TIME | TrainFilters.DEPARTURE_TIME
      }>
    ) => {
      const prevFilters = state.filters[action.payload.name]
      state.filters[action.payload.name] = getTimeOfDayFilters({
        filteredTrains: action.payload.filteredTrains,
        initTrains: action.payload.initTrains,
        name: action.payload.name,
        previousTimeSlots: prevFilters,
      })
    },
  },
})

export const {
  resetFilters,
  setFilterRange,
  setFilterStation,
  setFilterTimeOfDay,
  setFilterTravelClass,
  setInitFiltersRangeValue,
  setInitFiltersTimeOfDay,
  setInitTrains,
  setInitTrainsFilters,
  setIsFilterByRecommendedStations,
  setIsShowNotification,
  setTravelClassFilters,
  setUpdateFiltersStation,
  setUpdateFiltersTimeOfDay,
} = trainFiltersSlice.actions

export default trainFiltersSlice.reducer
